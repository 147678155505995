<div class="smallImageBox" on:click on:pointerdown={() => active = true} on:pointerup={() => active = false}>
    <div style={styleString}>
    <hr>
        <figure>
            <div><h1>{name}</h1></div>
            <img src={img} alt="">
        </figure>
    </div>
</div>

<script>

export let active;
export let img;
export let name;
export let activeColor = "#d6effa";
export let backgroundColor = "";

let styleString = "";

$: styleString = `background-color: ${active ? activeColor : backgroundColor}`


</script>

<style>
/*-- samllImageBox --*/

.smallImageBox > div > figure{
    /* width: calc(50% - 28px); */
    margin: 0px 14px;
}
figure > img {
    width: 100%;
}
h1{
    position: absolute;
    padding-top: 6px; 
}
.smallImageBox > div > figure > div{
    position: absolute;
    width: calc(50% - 28px);
}
.smallImageBox > div > figure > img{
    margin-left: -14px;
}
div.smallImageBox:nth-last-of-type(1) > div > figure, div.smallImageBox:nth-last-of-type(2) > div > figure{
    border-bottom: 1px solid; 
}
</style>



