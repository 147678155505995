<header>
    <div class="icon" on:click="{()=> $router.route('/glossar')}">
        <img src="/assets/img/icons/31x23_glossar.svg" alt="">
    </div>
    <Breadcrumb count={detectionPath.length + 2} activeIndex={detectionPath.length+1} />
    <div class="title">
        <h1>{$translations.featuresHeadline}</h1>
    </div>
</header>
<main>
    <div class="descriptionBox">
        <hr>
        <div>
            <h1>{$translations.furtherFeatures}</h1>
            <p>{$translations.furtherFeaturesText}</p>
        </div>
        <hr>
    </div>
    {#if $characteristics}
    <div class="filter">
        <DropdownSelect activeColor="var(--identify-select)" title={$translations.formFeatures} options={[emptyOption, ...$characteristics.form]} on:selectOption="{(ev) => setSelection($translations.formFeatures, ev.detail.option) }" displayProperty={"name"+$langId} />
        <DropdownSelect activeColor="var(--identify-select)" title={$translations.materialtraceFeatures} options={[emptyOption, ...$characteristics.materialspur]} on:selectOption="{(ev) =>  setSelection($translations.materialtraceFeatures, ev.detail.option) }" displayProperty={"name"+$langId} />
        <DropdownSelect activeColor="var(--identify-select)" title={$translations.technologyFeatures} options={[emptyOption, ...$characteristics.technologie]} on:selectOption="{(ev) =>  setSelection($translations.technologyFeatures, ev.detail.option) }" displayProperty={"name"+$langId} />
        <DropdownSelect activeColor="var(--identify-select)" title={$translations.usageFeatures} options={[emptyOption, ...$characteristics.verwendung]} on:selectOption="{(ev) =>  setSelection($translations.usageFeatures, ev.detail.option) }" displayProperty={"name"+$langId} />
        <h1>
            <a href="/identify/result">
                {$translations.showResult}
            </a>
        </h1>
    </div>
    {/if}
</main>

<script>
import {onMount} from 'svelte';
import {slide, slideSpeed} from '@/transitions.js';
import {variableIdentifier, router} from '@/store.js';
import {identifyGroups, base, typefaces, translations, langId, characteristics} from '@/typoStore.js';
import DropdownSelect from '@components/DropdownSelect';
import Breadcrumb from '@/components/Breadcrumb.svelte';


let emptyOption = {_id: -1, name: "—", name_en: "—", name_fr: "—"};

let selectedCharacteristics = {};
let detectionPath = [];

onMount(() => {
    localStorage.setItem('typo_selectedCharacteristics', JSON.stringify({}));
    detectionPath = JSON.parse(localStorage.getItem('typo_detectionPath'));

});

function setSelection(category, value) {
    selectedCharacteristics[category] = value;
    localStorage.setItem('typo_selectedCharacteristics', JSON.stringify(selectedCharacteristics));
}


</script>

<style lang="css">
.descriptionBox > div {
    height: 100%;
    padding: 11.5px 14px 13px 14px;
}
.descriptionBox{
    margin-top: 56px;
}
header{
    background: var(--identify-nav);
}
main {
	background-color: var(--identify-main);
}
.filter h1{
    padding: 33.5px 14px 13px 14px;
}
</style>