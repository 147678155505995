<footer style={bgVariable}>
       <Nav/> 
</footer>

<script>
import Nav from '@components/Nav.svelte'
import {variableIdentifier} from '@/store.js'

$: bgVariable = `background-color: var(--${$variableIdentifier}-nav)`;


</script>

<style lang="css">

footer{
	width: 100%;
    height: 44px;
    position: fixed;
    z-index: 2;
    bottom: 0;
}

</style>
