<script>
  import { createEventDispatcher } from "svelte";
  import { router } from "@/store.js";
  import { base } from "@/typoStore.js";

  export let videoUrl = "";

  const dispatch = createEventDispatcher();
</script>

<style>
  header {
    background-color: black;
  }

  .icon {
    filter: invert();
  }

  video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    min-width: 100%;
    min-height: 100%;
    z-index: 2;
  }
  main {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
</style>

<header>
  <div class="icon" on:click={() => dispatch('close')}>
    <img src="/assets/img/icons/23x23_wrong.svg" alt="" />
  </div>
</header>
<main>
  <video
    src={base + '/cockpit/storage/uploads' + videoUrl}
    muted
    autoplay
    playsinline />
</main>
