 <script>
 import {starredTypefaces} from '@/typoStore.js';
 export let id;
 export let name;
 export let year;
 export let imgSrc;
 export let isStarred = false;
 export let showImage = false;

 let isSelected = false;

//  function starTypeface()
//     ;



 </script>

 <div {id} class="catalog" on:click class:isSelected on:pointerdown={() => isSelected = true} on:pointerup={() => isSelected = false} >
    <hr>
    <div >
        <h1>{name}</h1>
        <p>{year}</p>
        <div on:click|stopPropagation="{() => starredTypefaces.star(id)}">
            {#if isStarred}
            <img src="/assets/img/icons/20x19_star.svg" alt="">
            {:else}
            <img src="/assets/img/icons/20x19_star_inactive.svg" alt="">
            {/if}
        </div> 
    </div>
    {#if showImage}
    <div class="catalogImageContainer">
        <div class="shadowHorizontal"></div>
        <div class="catalogImage">
            <img src={imgSrc} alt="">
        </div>
    </div>
    {/if}
</div>


<style>


.catalog > div:not(.catalogImageContainer){
    display: grid;
    padding: 7.5px 14px 7px 14px;
    grid-template-columns: 4fr 2fr 25px;
}
.catalog > div > div{
    justify-self: left;
}
.catalog > div > div:not(.catalogImage) > img{
    height: 16px;
    display: inline;
}

.isSelected {
    background-color: var(--institutional-select);
}
.shadowHorizontal{
    position: absolute;
    right: 0;
    z-index: 1;
    width: 75%;
    height: 40px;
    pointer-events: none;
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 53%, rgb(237,237,237) 99%, rgba(237,237,237,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 53%,rgba(237,237,237,1) 99%,rgba(237,237,237,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,0) 53%,rgba(237,237,237,1) 99%,rgba(237,237,237,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
.catalogImageContainer{
    padding: 0 !important;
    width: 100%;
    height: 45px;
}
.catalogImage{
    height: 60px;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden; 
}
.catalogImage img{
    height: 135%;
    margin-top: -33px;
    margin-left: 0px;
}

</style>