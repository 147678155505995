<header>
        <div class="icon" on:click="{()=> $router.route('/glossar')}">
            <img src="/assets/img/icons/31x23_glossar.svg" alt="">
        </div>
        <Breadcrumb count={detectionPath.length + 2} activeIndex={ parseInt(detectionIndex) + 1} />
        
        <div class="title">
            <h1>{category['headline'+$langId]}</h1>
        </div>
</header>
<main>
	<div in:slide="{{duration: animateTransition ? slideSpeed : 1}}">
	{#if populatedOptions}
		{#each populatedOptions as option, index}
			<ImageBox on:click="{() => nextPath(option)}" img={getRandomImage([selectedOptions[0], options[index]], $typefaces)} activeColor="var(--identify-select)" text={option['name'+$langId]} />
		{/each}
	{/if}
	</div>
</main>

<script>
import {onMount} from 'svelte';
import ImageBox from '@/components/ImageBox.svelte';
import Breadcrumb from '@/components/Breadcrumb.svelte';
import {slide, slideSpeed} from '@/transitions.js';
import {variableIdentifier, router} from '@/store.js';
import {typefaces, base, langId, characteristics} from '@/typoStore.js';
export let animateTransition = true;

export let detectionIndex;

let detectionPath = [];
let selectedOptions = [];
let populatedOptions = [];

let options = [];
let category = {};

$: detectionIndex, category = detectionPath.length > 0 ?  detectionPath[detectionIndex] : {};
$: options = category.options ?  category.options : [];
$: selectedOptions = JSON.parse(localStorage.getItem('typo_selectedOptions')).slice(0,detectionIndex+1);
$: if($characteristics) populatedOptions = options.map(op => {
    let result;
    Object.values($characteristics).forEach(array => {
        let temp = array.find(c => c._id == op._id);
        if(temp != null) result =  temp;

    });
    return result;
});


onMount(() => {
    detectionPath = JSON.parse(localStorage.getItem('typo_detectionPath'));
    options = options;

})

function getRandomImage(arr, src) {
    if(src) {

        let results = src.filter(t =>  {
            let isMatch = true;
            arr.forEach(obj => {
                if(obj.link) {
                    let linkedProp = t[obj.link];
                    if (linkedProp == null) {
                        linkedProp = t[obj.link.slice(0,-1)];  //slice away last character because of database inconsistency between "identifySubGroups" (on indentifyPages) and "identifySubGroup" (on typefaces)
                    }
                        
                    if(linkedProp == null || linkedProp._id != obj._id)
                        isMatch = false;
                }
                else {
                    if(t.identifyGroup._id != obj._id)
                        isMatch = false;
                    
                }
            });
            return isMatch;
        });
        if(results && results.length > 0) {
            let randomTypeface = results[Math.floor(Math.random()*(results.length-1))];
            if(randomTypeface.identifyImages)
			    return base + randomTypeface.identifyImages[Math.floor(Math.random()*(randomTypeface.identifyImages.length-1))].path;
		}
    }
    return "";
}

function nextPath(option) {
    // if(option.link != "strichkontrast")
    selectedOptions.push(option);
    localStorage.setItem('typo_selectedOptions', JSON.stringify(selectedOptions));

    setTimeout(() => {
        if(detectionIndex < detectionPath.length-1) {
            // detectionIndex += 1   
            $router.route(`/identify/${Number.parseInt(detectionIndex)+1}`);
        }
        else
            $router.route('/identify/characteristics');
    }, 100);

}
</script>

<style lang="css">
header {
	background-color: var(--identify-nav);
}
main {
	background-color: var(--identify-main);
}
</style>
