<script>
import {createEventDispatcher, tick} from 'svelte';

const dispatch = createEventDispatcher();

export let options = [];
export let title;
export let displayProperty;
export let active = false;
export let activeColor = "var(--play-select)";
export let sort = true;

let noSelectionPlaceholder = '—';

let currentSelection = null;
let showOptions = false;
let optionHeight = 0
let selectEl;
// let isActive = false;

let sortedOptions = [];


$: if(sort == true && options) {
    sortedOptions = options.sort((a,b) => {
        if( a[displayProperty] && b[displayProperty] && a[displayProperty].toLowerCase() > b[displayProperty].toLowerCase()) return 1;
        else return -1;

    });
}
else sortedOptions = options;

$: displaySelection = currentSelection && currentSelection.hasOwnProperty(displayProperty) ? currentSelection[displayProperty] : noSelectionPlaceholder;
$: if(showOptions === true) {
    tick().then(() => optionHeight = selectEl.offsetHeight)
    active = true;
}
else {
    active = false;
}


function selectOption(option, index) {
    currentSelection = option;
    dispatch('selectOption', {option, index});
}

</script>

<div class="dropDownSelect" tabindex="-1"   style={active ? `background-color: ${activeColor};` : 'transparent'}>
    <div class="select_wrapper" on:click="{() => showOptions = !showOptions}">
            <div class="selected_wrapper">
            <h1>{title}</h1>
            <div class="selected">
                <p>{displaySelection}</p>
            </div>
            <div class="icon" class:active="{showOptions}" >
                <img src="/assets/img/icons/16x11_arrow.svg" alt="">
            </div>
        </div>        
        <div bind:this={selectEl} class="select" class:showOptions>
            <div></div>
            <div class="select_inner">
            <hr>
                {#each sortedOptions as option, index}
                    <p on:click="{() => selectOption(option, index)}">{option[displayProperty]}</p>
                {/each}
            </div>
        </div>
        <hr>
    </div>
</div>
<style>
/*----- dropdownSelect -----*/
.selected_wrapper{
    display: grid;
    grid-template-columns: 2fr 3fr max-content;
    padding: 11.5px 0px 13px 0px;
    width: calc(100% - 28px);
    margin-left: 14px;
}
.select{
    display: grid;
    grid-template-columns: 15fr 23fr;
    overflow: scroll;
    height: 0px;
    transition: height 300ms;
}
.showOptions {
    height: 150px;
}
.select_inner p{
    padding-top: 13.5px;
}
.select_inner hr{
    width: calc(60% - 14px);
    right: 14px;
    position: absolute;
}

</style>