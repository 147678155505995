
<div class="zoomcontainer" on:mousemove="{(ev) => moveZoom(ev)}" on:touchmove="{(ev) => moveZoom(ev)}" on:mouseleave={() => showMag = false}  on:touchleave={() => showMag = false} on:touchend={() => showMag = false}>
	<div>
		<img  bind:this={imageElement} draggable="false" src= {img} alt="">
	</div>

	<div class="zoom_icon">
		<img class:up src="/assets/img/icons/27x27_lupe_zoom.svg" alt="">
	</div>

	<div class="zoom" style={zoomPosition} class:show={showMag}>
		<img style={zoomTranslate} src={img} alt=""/>
	</div>
</div>


<script>
export let img = "https://typ-o.design.fh-aachen.de/cockpit/storage/uploads/2020/01/13/5e1c57bd9b306Adelle-K1.svg";
export let scale = 4;
export let backgroundColor = "#fadceb";
export let up = false;

let x;
let y;

	
let showMag = false;
let imageElement;
let zoomTranslate = "";
let zoomPosition = "";
	
$: if(imageElement) zoomTranslate = `transform: translate(${(-clampedWidth+100)*1.3}px,${(-clampedHeight+100)*1.3}px)`;
$: if(imageElement) zoomPosition = `transform: translate(${clampedWidth-100}px,${clampedHeight-250}px) scale(${scale}); background-color: ${backgroundColor};`;

let clampedWidth = 0;
let clampedHeight = 0;


$: if(imageElement) clampedWidth = clamp(x, 0, imageElement.width + 200);
$: if(imageElement) clampedHeight = clamp(y, 0, imageElement.height );


function clamp(value, min, max) {
	if(value < min) return min;
	if(value > max) return max;
	return value;
}
	
function moveZoom(ev) {
	let rect = ev.target.getBoundingClientRect();

	let clientX = ev.clientX || ev.touches[0].clientX;
	let clientY = ev.clientY || ev.touches[0].clientY;

	x = clientX - rect.left; //x position within the element.
	y = clientY - rect.top;  //y position within the element.
	showMag = true;
	ev.preventDefault();
}
</script>

<style>
	.zoomcontainer > div:first-child > img {
		width: 100%;
		/* width: 120px; */
		/* position: absolute; */
		-webkit-touch-callout: none !important;
		-webkit-user-select: none !important;

	}

	.zoomcontainer {
		display: block;
		position: relative;
		width: 100%;
		/* height: 185px; */
		/* grid-template-columns: 1fr 48px; */
		/* width: calc(100vw - 14px); */
		/* height: 185px; */
	}
	.show {
		display: block !important;
	}
	.zoom {
		display: none;
		pointer-events: none;
		position: absolute;
		width: 45px;
		height: 45px;
		border-radius: 25px;
		box-shadow: 0px 0px 10px black;
		overflow: hidden;
		z-index: 999;
	}

	.zoom_icon {
		position: absolute;
		right: 24px;
		bottom: 24px;
		padding-right: 14px;
	}
	.zoom_icon > img{
		width: 24px;
		padding: 0 0 0 0 !important;

	}
	.up{
		top: 14px;
	}

</style>