<header>
    <div class="icon" on:click="{() => $router.route("/learn")}">
        <img src="/assets/img/icons/16x11_arrow_back.svg" alt="">
    </div>

    <div class="breadcrumb">
    {#if group}
        <h1>{group['name'+$langId]}</h1>
    {/if}
    </div>

    <div class="title">
        <div class="icon" class:hide={groupIndex < 1} on:click="{() => $router.route('/learn/group/' + $groups[(groupIndex - 1)]._id)}">
            <img src="/assets/img/icons/16x11_arrow_back.svg" alt="">
        </div>
        {#if $groups }
        <div class="icon" class:hide={groupIndex > $groups.length - 2} on:click="{() => $router.route('/learn/group/' +$groups[(groupIndex + 1)]._id)}">
            <img src="/assets/img/icons/16x11_arrow_forward.svg" alt="">
        </div>
        {/if}
    </div>
</header>
<main>
    <div in:slide="{{duration: animateTransition ? slideSpeed : 1}}">
    {#if group}
        <div class="wysiwyg">
            <SvokZoom img={base+group.learnPangrams[Math.floor(Math.random() * group.learnPangrams.length)].path} />
            <!-- <img src={base+group.learnPangrams[Math.floor(Math.random() * group.learnPangrams.length)].path} alt=""> -->
            <!-- <p>{@html }</p> -->
            <WYSIWYG content={group['wysiwg'+$langId]}/>
        </div>
        <hr>
         {#if group['languageSynonyms'+$langId]}
        <Dropdown activeColor="var(--learn-select)" title={$translations.synonyms} showContent={dropdownDefaultState}>
            <div class="synonymBoxInner">
                {#each group['languageSynonyms'+$langId] as syn}
                <p>{syn.value.code}</p>
                <h1>{syn.value.name}</h1>
                {/each}
            </div>
        </Dropdown>
        {/if}
        {#if group['history'+$langId]}
        <Dropdown activeColor="var(--learn-select)" title={group['history'+$langId]['displayName']} showContent={dropdownDefaultState}>
            <WYSIWYG content={group['history'+$langId]['description']}/>
        </Dropdown>
        {/if}
        {#if group['explanation'+$langId]}
        <Dropdown activeColor="var(--learn-select)" title={group['explanation'+$langId]['displayName']} showContent={dropdownDefaultState}>
            <WYSIWYG content={group['explanation'+$langId]['description']}/>
        </Dropdown>
        {/if}
        {#if group.subGroups}
        <Dropdown activeColor="var(--learn-select)" title={$translations.subgroups} showContent={dropdownDefaultState}>
            <div>
                {#each group.subGroups as sg}
                <div class="wysiwygSubgroup" on:click="{() => $router.route(`/learn/subGroup/${sg._id}`)}">
                    <a href={`/learn/subGroup/${sg._id}`}><p>{sg['name'+$langId]}</p></a>
                    <img src={base+sg.images.multiLinePangram[Math.floor(Math.random() * sg.images.multiLinePangram.length)].path} alt="">
                </div>
            <div></div>
            {/each}
            </div>
        </Dropdown>  
        {/if}
    {/if}
    </div>
</main>

<script>
    import Dropdown from '@components/Dropdown.svelte';
    import WYSIWYG from '@components/WYSIWYG.svelte';
    import SvokZoom from '@components/SvokZoom.svelte';
    import {translations, groups, base, langId} from '@/typoStore.js';
    import {router} from '@/store.js';
    import {slide, slideSpeed} from '@/transitions.js';
    export let groupId;
    export let animateTransition;


    let dropdownDefaultState = true;
    let group;
    let groupIndex = 0;
    $: if($groups) group =  $groups.find(g => g._id == groupId)
    $: if($groups && group) groupIndex = $groups.indexOf(group);

</script>

<style>

header {
    background: var(--learn-nav);
}

main {
    background-color: var(--learn-main);
    margin-bottom: 44px;
}
/*----- synoynmBox -----*/
:global(.synonymBox > div) {
    display: grid;
    padding: 11.5px 14px 13px 14px;
    grid-template-columns: auto max-content;
}
:global(.synonymBox > div > h1) {
    padding-bottom: 11.5px;
}
.synonymBoxInner {
    display: grid;
    grid-template-columns: 4fr 22fr;
    padding-bottom: 13px;
}

.wysiwygSubgroup{
    grid-area: footer footer;
    border-top: 1px solid;
    padding: 11.5px 0px 0px 0px;
}
.wysiwygSubgroup > img{
    min-height: 150px;
    width: calc(100% + 4vw);
    margin-left: -4vw;
}

/*----- WYSIWYG -----*/
:global(.wysiwyg > img){
    width: 100%;
}
:global(.wysiwyg p){
    padding: 0px 14px 13px 14px;
}
:global(.wysiwyg > .wysiwyg-wrapper > p:last-of-type){
	padding-bottom: 11.5px !important;
}
:global(.descriptionBox .wysiwyg-wrapper > p:last-of-type){
	padding-bottom: 13px !important;
}
:global(.descriptionBox .wysiwyg-wrapper > hr){
    margin-left: 0px;
    width: calc(100% - 14px);
}

.breadcrumb > h1 {
    line-height: 24px;
}

header{
    grid-template-columns: 17% calc(83% - 80px) 80px;
}

.hide {
    visibility: hidden !important;
}
</style>