<nav>
	<ul>
		
		<li>
			<!-- <a href="/identify">
				<div class="nav_wrapper" class:active={checkURLActive($windowPath,"/identify")}>
						<img src="/assets/img/icons/27x28_menu_erkennen.svg" alt="">		
						<p class:show={$windowPath && $windowPath.startsWith("/identify")} style="overflow: hidden">{$translations.identify}</p>
						
				</div>
			</a> -->

			<NavItem path="/identify" height="80%" img="/assets/img/icons/27x28_menu_erkennen.svg" name={$translations.identify}/>
		</li>	
		<li>
			<!-- <a href="/learn">
				<div class="nav_wrapper" class:active={checkURLActive($windowPath,"/learn")}>
						<img src="/assets/img/icons/27x36_menu_lernen.svg" alt="">
						
						<p class:show={$windowPath && $windowPath.startsWith("/learn")} style="overflow: hidden">{$translations.learn}</p>
						
				</div>
			</a> -->
			<NavItem path="/learn" img="/assets/img/icons/27x36_menu_lernen.svg" name={$translations.learn}/>
		</li>	
		<li>
			<!-- <a href="/play">
				<div class="nav_wrapper" class:active={checkURLActive($windowPath,"/play")}>
						<img src="/assets/img/icons/27x36_menu_spielen.svg" alt="">
						<p class:show={$windowPath && $windowPath.startsWith("/play")} style="overflow: hidden">{$translations.play}</p>
						
				</div>
			</a> -->
			<NavItem path="/play" height="99%" img="/assets/img/icons/27x36_menu_spielen.svg" name={$translations.play}/>
		</li>	
		<li>
			<!-- <a href="/catalog">
				<div class="nav_wrapper" class:active={checkURLActive($windowPath,"/catalog")}>
					<img on:click="{() => showForSomeTime()}" src="/assets/img/icons/40x30_menu_katalog.svg" alt="">
					<p class:show={$windowPath && $windowPath.startsWith("/catalog")} style="overflow: hidden">{$translations.catalog}</p>
				</div>
			</a> -->
			<NavItem path="/catalog" height="90%" img="/assets/img/icons/40x30_menu_katalog.svg" name={$translations.catalog}/>
		</li>	
		<li>
			<!-- <a href="/info">
				<div class="nav_wrapper" class:active={checkURLActive($windowPath,"/info")}>
						<img src="/assets/img/icons/27x27_menu_info.svg" alt="">
				</div>
			</a> -->
			<NavItem path="/info" height="80%" img="/assets/img/icons/27x27_menu_info.svg"/>

		</li>	
		
	</ul>          
</nav>


<script>
	import { slide, fade } from 'svelte/transition';
	import { onMount } from 'svelte';
	import {windowPath} from '@/store.js';
	import {translations} from '@/typoStore.js';
	import NavItem from '@components/NavItem.svelte';


	let routes;
	
	$: if($translations) {
		routes = [
		{name: $translations.identify, path: "/identify", img: "/assets/img/icons/27x28_menu_erkennen.svg"},
		{name: "Lernen", path: "/learn", img: "/assets/img/icons/27x36_menu_lernen.svg"},
		{name: "Spielen", path: "/play", img: "/assets/img/icons/27x36_menu_spielen.svg"},
		{name: "Katalog", path: "/catalog", img: "/assets/img/icons/40x30_menu_katalog.svg"},
		{name: "Info", path: "/info", img: "/assets/img/icons/27x27_menu_info.svg"}
	
	];
	} 


	let stuck = false;

	function checkURLActive(url, match){
		return url && url.startsWith(match);
	}

	function onScroll(ev) {
		stuck = window.pageYOffset > 0;
	}

	$: classes = 'nav' + (stuck ? ' stuck' : '');

	onMount(() => {
		addEventListener('scroll', onScroll, { passive:true });

		return () => {
			removeEventListener('scroll', onScroll);
		}
	});

	
</script>

<style lang="css">
nav > ul{
    display: grid;
    grid-template-columns: auto auto auto auto 60px;
    left: 14px;
    width: calc(100% - 28px);
    position: absolute;
	overflow: hidden;
}
nav > ul > li{
	list-style: none;
}
:global(nav > ul > li:last-of-type .nav_wrapper ){
	padding-left: 27px;
    padding-right: 0px;
}
</style>
