<header>
    <div class="icon" on:click="{() => $router.route(`/learn/group/${parentGroupId}`)}">
        <img src="/assets/img/icons/16x11_arrow_back.svg" alt="">
    </div>
    <!-- <Breadcrumb count=5 activeIndex=0 /> -->
    <div class="title">
    {#if group}
        <h1>{group['name'+$langId]}</h1>
    {/if}
    </div>
    <div class="title">
        <div class="icon"class:hide={groupIndex < 1} on:click="{() => previousSubGroup()}">
            <img src="/assets/img/icons/16x11_arrow_back.svg" alt="">
        </div>
        {#if parentGroup}
        <div class="icon" class:hide={groupIndex > parentGroup.subGroups.length - 2} on:click="{() => nextSubGroup()}">
            <img src="/assets/img/icons/16x11_arrow_forward.svg" alt="">
        </div>
        {/if}
    </div>
</header>
<main >
    <div bind:this={container} in:slide="{{duration: animateTransition ? slideSpeed : 1}}">
    {#if group}
        <div class="wysiwyg">
            <!-- <img src={base+group.images.multiLinePangram[0].path} alt=""> -->
            <SvokZoom img={base+group.images.multiLinePangram[Math.floor(Math.random() * group.images.multiLinePangram.length)].path} />

            <!-- <p>{@html }</p> -->
            <WYSIWYG content={group['description'+$langId]}/>
        </div>
        <hr>
         {#if group['languageSynonyms'+$langId]}
        <Dropdown activeColor="var(--learn-select)" title={$translations.synonyms} showContent={dropdownDefaultState}>
            <div class="synonymBoxInner">
                {#each group['languageSynonyms'+$langId] as syn}
                <p>{syn.value.code}</p>
                <h1>{syn.value.name}</h1>
                {/each}
            </div>
        </Dropdown>
        {/if}
        {#if group['history'+$langId]}
        <Dropdown activeColor="var(--learn-select)" title={group['history'+$langId]['name']} let:updateMe showContent={dropdownDefaultState}>
            <WYSIWYG on:loadedcontent={updateMe} content={group['history'+$langId]['description']}/>
        </Dropdown>
        {/if}
        {#if group['form'+$langId]}
        <Dropdown activeColor="var(--learn-select)" bind:this={form} title={group['form'+$langId]['name']} let:updateMe showContent={dropdownDefaultState}>
            <WYSIWYG on:loadedcontent={updateMe} content={group['form'+$langId]['description']}/>
        </Dropdown>
        {/if}
        {#if group['characteristica'+$langId]}
        <Dropdown activeColor="var(--learn-select)" title={group['characteristica'+$langId]['name']} let:updateMe showContent={dropdownDefaultState}>
            <WYSIWYG on:loadedcontent={updateMe} content={group['characteristica'+$langId]['description']}/>
        </Dropdown>
        {/if}
    {/if}
    </div>
</main>

<script>
    import Dropdown from '@components/Dropdown.svelte';
    import WYSIWYG from '@components/WYSIWYG.svelte';
    import SvokZoom from '@components/SvokZoom.svelte';
    import Breadcrumb from '@components/Breadcrumb.svelte';
    import {onMount} from 'svelte';
    import {translations, subGroups, base, langId, groups} from '@/typoStore.js';
    import {router} from '@/store.js';
    import {slide, slideSpeed} from '@/transitions.js';
    export let groupId;
    export let animateTransition;
    let container;
    let group;
    let dropdownDefaultState = true;

    onMount(() => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
            });
    })

//element bindings
let form;
let groupIndex = 0;
let parentGroup;
let parentGroupId;
$: if($subGroups && groupId) group =  $subGroups.find(g => g._id == groupId)
$: if(group && $groups) parentGroup = $groups.find(g => {
    if(Array.isArray(g.subGroups)) {
        let pG = g.subGroups.find(sg => sg._id == groupId);
        if (pG && pG._id) return true;
        else return false;
    }
    else
        return false;
});
$: if(parentGroup && group) groupIndex = parentGroup.subGroups.findIndex(g => g._id == group._id);
$: if(parentGroup) parentGroupId = parentGroup._id;

function nextSubGroup() {
    let routeGroupId = parentGroup.subGroups[groupIndex + 1]._id;
    $router.route('/learn/subGroup/'+routeGroupId);

}

function previousSubGroup() {
    let routeGroupId = parentGroup.subGroups[(groupIndex - 1)]._id;
    $router.route('/learn/subGroup/'+routeGroupId);
}

</script>

<style>

header {
    background: var(--learn-nav);
}

main {
    background-color: var(--learn-main);
    margin-bottom: 44px;
}
/*----- synoynmBox -----*/
:global(.synonymBox > div) {
    display: grid;
    padding: 11.5px 14px 13px 14px;
    grid-template-columns: auto max-content;
}
:global(.synonymBox > div > h1){
    padding-bottom: 11.5px;
}
.synonymBoxInner {
    display: grid;
    grid-template-columns: 4fr 22fr;
    padding-bottom: 13px;
}

:global(.wysiwygSubgroup){
    grid-area: footer footer;
    border-top: 1px solid;
    padding: 11.5px 0px 0px 0px;
}
:global(.wysiwygSubgroup > img){
    width: 100%;
}
:global(.wysiwyg-wrapper > p:last-of-type){
    padding-top: 13px;
    padding-bottom: 44px !important;
}
/*----- WYSIWYG -----*/
:global(.wysiwyg > img){
    min-height: 150px;
    width: calc(100% + 4vw);
    margin-left: -4vw;
}
:global(.wysiwyg p){
    padding: 0px 14px 13px 14px;
}
header{
    grid-template-columns: 17% calc(83% - 80px) 80px;
}

.hide {
    visibility: hidden !important;
}
</style>