
<div class="imageBox" on:click tabindex="-1" on:pointerdown={() => active = true} on:pointerup={() => active = false}>
<!-- <div class="imageBox" on:click tabindex="-1" on:focusin={() => active = true} on:focusout={() => active = false}>  needs additional setTimeout in afterUpdate function -->

    <div style={styleString} class="wrapper">
    <figure>
        {#if isQuestion}
            <div>
            {#if isAnswered}
                {#if isRightAnswer}
                    <img class="right-answer-icon" src="assets/img/icons/22x23_right.svg" alt="">
                {:else}
                    <img src="assets/img/icons/23x23_wrong.svg" alt="">
                {/if}
            {/if}
            </div>
        {:else}
            <div>
                <h1>{text}</h1>
            </div>
        {/if}
        <img src={img} alt="">
    </figure>
    <hr>
    </div>
</div>
<script>

import {afterUpdate, onMount} from 'svelte';

export let img;
export let text;
export let active;
export let isQuestion = false;
export let isAnswered = false;
export let isRightAnswer = false;
export let activeColor = "var(--play-select)";
export let backgroundColor = "";
let styleString = "";

afterUpdate(() => {
    // setTimeout(() => active = false, 100);
});

onMount(() => {
    active = false;
})

$: styleString = `background-color: ${active ? activeColor : backgroundColor}`

</script>

<style>

/*-- imageBox --*/
.imageBox > div > figure{
    width: calc(100% - 28px);
    height: 100%;
    margin: 0px 14px;
    z-index: 0;
    min-height: 112px;

}
.imageBox > div > figure > div{
    position: absolute;
    left: calc(60% - 14px);
    padding-top: 6px;
    width: 40%;
}
.imageBox > div > figure > div > img{
    float: right;
    height: 21px;
    margin: 7px 14px 0px 0px;
}
.imageBox > div > figure > img{
    margin-left: -14px;
}


.imageBox {
    height: 100%;
}

.wrapper {
    height: 100%;
}

figure > img {
    max-height: 100%;
    max-width: 100%;
}

div > img.right-answer-icon {
    animation: blinker step-end 0.7s infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


/* .active{
    background-color: ;
} */

</style>