import { readable, writable, derived } from 'svelte/store';


export const windowPath = writable(window.location.pathname);

const pathMatcher = /\/(.+)/;

export const variableIdentifier = derived(windowPath, $windowPath => {
    let match = pathMatcher.exec($windowPath);

    let id = "identify";
    if(match != null && match.length > 1) {
        let els = match[1].split('/');  
        id = els[0];      
        if(id === "typefaces") id = "catalog";
        if(id === "") id = els[2];
    }
        
    
    return id;
});

export const router = writable(null);
