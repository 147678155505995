import { readable, writable, derived, get } from 'svelte/store';

// export const typefaces = readable([], set => {   
//     getTypefaces().then(res => res.ok ? res.json() : null).then(res => {    
//         if(res) {
//             set(res.entries);
//         }
//     });  
// });

export const language = writable(localStorage.getItem('typo_language') ? localStorage.getItem('typo_language') : 'en');  //set to 'de', 'en' or 'fr'

language.subscribe(value => {
    localStorage.setItem('typo_language', value);
})

export const langId = derived(language, $language => {
    if ($language == 'de') return '';
    else if ($language == 'en') return '_en';
    else if ($language == 'fr') return '_fr';
    else if ($language == 'es') return '_es';
    else return '';
})


/** Optional way of making sure that api request is not triggered with each changing view, has the drawback that it does not update content automatically */
export const typefaces = readable(null, (set) => {
    // {
    getTypefaces().then(res => res.ok ? res.json() : null).then(res => {
        if (res) {
            set(res.entries);
        }
    });
    // }
});

export const groups = readable(null, (set) => {
    // if(get(groups) === null) {
    getGroups().then(res => res.json()).then(res => {
        set(res.entries);
    });
    // }
});

export const subGroups = readable(null, (set) => {
    // if(get(groups) === null) {
    getSubgroups().then(res => res.json()).then(res => {
        set(res.entries);
    });
    // }
});

export const identifyGroups = readable(null, (set) => {
    getIdentifyGroups().then(res => res.json()).then(res => {
        set(res.entries);
    });

});

export const translationIndex = readable(null, (set) => {
    if (get(translationIndex) === null) {
        getTranslations().then(res => res.ok ? res.json() : null).then(res => {

            set(res);
        });
    }
});

export const formPrinciples = derived(language, ($language, set) => {
    // if(get(formPrinciples) === null) {
    getFormPrinciples().then(res => res.ok ? res.json() : null).then(res => {
        set(res.entries);
    });
    // }
})

export const characteristics = readable(null, (set) => {
    // if(get(typefaces) === null) {
    getCharacteristics().then(result => set(result));
    // } 
});

export const glossar = readable(null, (set) => {
    // if(get(typefaces) === null) {
    getGlossarEntries().then(res => res.ok ? res.json() : null).then(res => {
        set(res.entries);
    });
    // } 
});

export const institutional = readable(null, (set) => {
    getInstitutionalEntries().then(res => res.ok ? res.json() : null).then(res => {

        set(res);
    });
});

export const publishedLanguages = readable(null, (set) => {
    getPublishedLanguages().then(res => res.ok ? res.json() : null).then(res => {
        set(res);
    });
});

export const playResultInfo = readable(null, (set) => {
    getPlayResultInfo().then(res => res.ok ? res.json() : null).then(res => {
        set(res);
    });
});

export const contentVersion = readable(null, (set) => {
    getContentVersion().then(res => res.ok ? res.json() : null).then(res => {
        set(res);
    });
});

export const questions = readable(null, (set) => {
    // if(get(questions) === null) {
    getQuestions().then(res => res.ok ? res.json() : null).then(res => {
        set(res.entries);
    });
    // }   
});


export const translations = derived([language, translationIndex], ([$language, $translationIndex], set) => {
    if ($translationIndex)
        set($translationIndex[$language]);
    else set({});
});


export const starredTypefaces = localStoreStars();

function localStoreStars() {
    const { subscribe, set, update } = writable(JSON.parse(localStorage.getItem('starred')));

    return {
        subscribe,
        star: (val) => update(arr => {
            if (arr == null) arr = [];
            if (!arr.includes(val)) arr.push(val);
            else {
                let index = arr.indexOf(val);
                if (index > -1) {
                    arr.splice(index, 1);
                }
            }
            localStorage.setItem('starred', JSON.stringify(arr));
            return arr;
        })
    };
}



let envServer;
// if(process.env.NODE_ENV && process.env.NODE_ENV == "development")
//     envServer = 'http://localhost:5000';
// else
envServer = 'https://typ-o.design.fh-aachen.de';

export const base = envServer;

const apiUrl = `${base}/cockpit/api`;

const token = "429f030caaeeb24c3130173bfa87d8"

const api = function (endpoint) {
    // let url = `${endpoint}?token=${token}&lang=${get(language)}&populate=1`;
    let url = `${endpoint}?token=${token}&populate=1`
    return fetch(apiUrl + url);
    //     , {
    //     method: 'get',
    //     // headers: { 'Content-Type': 'application/json' },
    //     // body: JSON.stringify({
    //     //     // filter: {published:true},
    //     //     // limit: 10,
    //     //     // skip: 5,
    //     //     // sort: {_created:-1},
    //     //     populate: 1, // resolve linked collection items

    //     //     lang: get(language) // return normalized language fields (fieldA_de => fieldA)
    //     // })
    // });
}

function getGroups() { return api('/collections/get/groups') };

function getSubgroups() { return api('/collections/get/subGroups') };

function getTypefaces() { return api('/collections/get/typefaces') };

function getIdentifyGroups() { return api('/collections/get/identifyGroups') };


function getQuestions() { return api('/collections/get/questions') };

function getTranslations() { return api('/lokalize/project/globalTranslations') }

function getFormPrinciples() { return api('/collections/get/formprinzip') };

function getGlossarEntries() { return api('/collections/get/glossarGroups') };

function getInstitutionalEntries() { return api('/singletons/get/institutional') };

function getPublishedLanguages() { return api('/singletons/get/publishedLanguageKeys') };

function getPlayResultInfo() { return api('/singletons/get/gameResult') };

function getContentVersion() {
    return api('/singletons/get/contentVersion');

}



function getCharacteristics() {
    let characteristics = {};
    let form = api('/collections/get/form').then(res => res.ok ? res.json() : null).then(res => characteristics.form = res.entries);
    let verwendung = api('/collections/get/verwendung').then(res => res.ok ? res.json() : null).then(res => characteristics.verwendung = res.entries);
    let technologie = api('/collections/get/technologie').then(res => res.ok ? res.json() : null).then(res => characteristics.technologie = res.entries);
    let materialspur = api('/collections/get/materialspur').then(res => res.ok ? res.json() : null).then(res => characteristics.materialspur = res.entries);

    let formprinzip = api('/collections/get/formprinzip').then(res => res.ok ? res.json() : null).then(res => characteristics.formprinzip = res.entries);
    let serifen = api('/collections/get/serifen').then(res => res.ok ? res.json() : null).then(res => characteristics.serifen = res.entries);
    let strichkontrast = api('/collections/get/strichkontrast').then(res => res.ok ? res.json() : null).then(res => characteristics.strichkontrast = res.entries);
    let identifySubgroups = api('/collections/get/identifySubGroups').then(res => res.ok ? res.json() : null).then(res => characteristics.identifySubgroups = res.entries);


    return new Promise((resolve, reject) => {
        Promise.all([form, verwendung, technologie, materialspur, formprinzip, serifen, strichkontrast, identifySubgroups]).then(() => resolve(characteristics));
    });
}




