<header>
    <div class="icon" on:click="{() => history.back()}">
	    <img src="/assets/img/icons/23x23_wrong.svg" alt="">
	</div>
    <div class="breadcrumb">
    </div>
	<div class="title">
	{#if $translations}
        <h1>{$translations.glossaryHeadline}</h1>
	{/if}
    </div>
</header>
<main>
	<div class="descriptionBoxGlossar" in:slide="{{duration: animateTransition ? slideSpeed : 1}}">
	<hr>
	{#if $glossar}
		{#each getSorted($glossar, 'name'+$langId) as gGroup}
			<!-- <Dropdown bind:this={glossarGroups[gGroup._id]} animateTransition={true} title={gGroup['name'+$langId]} updateLayout={gGroup.updateLayout}
			 showContent={gGroup.glossarEntries.find(e => e._id === entryId)}> -->
			
			 <Dropdown activeColor="var(--learn-select)" bind:this={glossarGroups[gGroup._id]} animateTransition={true} title={gGroup['name'+$langId]} updateLayout={gGroup.updateLayout}
			 showContent={openDefaultState || gGroup.glossarEntries.find(e => e._id === entryId)} inFocus={gGroup.glossarEntries.find(e => e._id === entryId)} maxHeight=8000 >
				<div>
					<hr>
					{#each getSorted(gGroup.glossarEntries,'name'+$langId) as entry}
					{#if entry['name'+$langId] && entry['name'+$langId] != ""}
						<Dropdown 
							id={entry._id}		
							hideArrow={true} 
							title={entry['name'+$langId]} 
							scrollToElement={entry._id === entryId} 
							showContent={checkForId(gGroup, entry)}
							activeColor="var(--learn-select)"
							on:transitionstart="{() => {if(glossarGroups[gGroup._id]) glossarGroups[gGroup._id].forceAnimationUpdate(true);}}"
							on:transitionend="{() => {if(glossarGroups[gGroup._id]) glossarGroups[gGroup._id].forceAnimationUpdate(false);}}"
							let:updateMe>
							<WYSIWYG on:contentloaded={updateMe} content={entry['description'+$langId]}/>
						</Dropdown>
						{/if}
					{/each}
				</div>
			</Dropdown>
		{/each}
	{/if}
	</div>
</main>



<script>
import {tick, onMount} from 'svelte';
import Dropdown from '@components/Dropdown.svelte';
import WYSIWYG from '@components/WYSIWYG.svelte';
import {glossar, langId, translations} from '@/typoStore.js';
import {router} from '@/store.js';
import {slide, slideSpeed} from '@/transitions.js';
export let entryId;
export let animateTransition;
export let openDefaultState = false;


let glossarGroups = {};

onMount(() => {
})


function checkForId(parent, element) {
	if(entryId && element._id === entryId) {
		parent.showContent = true;

		return true;
	}
	return false;
}

function getSorted(array, field) {
	return array.sort((a,b) => {
		if(a[field] && b[field])
			return a[field].toLowerCase() < b[field].toLowerCase() ? -1 : 1;
		else return -1;
	})
}

</script>

<style lang="css">
header{
	background-color: var(--glossar-nav);
}
main {
	background-color: var(--glossar-main);
}
main > div{
	margin-top: 56px;
	margin-bottom: 44px;
}
:global(.descriptionBoxGlossar div.descriptionBox > div > h1){
	padding-bottom: 0 !important;
}
:global(.descriptionBoxGlossar > div.descriptionBox > div > div > div > .descriptionBox > div){
	padding-left: 0 !important;
	padding-right: 0 !important;
}
:global(.descriptionBoxGlossar > div.descriptionBox > div > div > div > .descriptionBox > hr){
	margin-left: 0 !important;
	margin-top: 8px !important;
}
:global(.descriptionBoxGlossar > div.descriptionBox > div > div > div > hr){
	margin-left: 0 !important;
	margin-top: 8px !important;
}
:global(.descriptionBoxGlossar > div.descriptionBox > hr){
	margin-top: 8px !important;
}
:global(.descriptionBoxGlossar > div.descriptionBox > div > div > div > div.descriptionBox:first-of-type > div > h1){
	padding-top: 8px !important;
}
:global(.descriptionBoxGlossar > div.descriptionBox > div > div > div > hr){
	margin-left: 0 !important;
	width: calc(100% - 28px);
	position: absolute;
}
:global(.wysiwyg-wrapper > p:last-of-type){
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
:global(div.descriptionBoxGlossar > div.descriptionBox:last-of-type > div > div > div > div.descriptionBox:last-of-type > div > h1){
	padding-bottom: 8px !important;
}
:global(.descriptionBoxGlossar .descriptionBox .wysiwyg-wrapper > p:last-of-type){
	padding-bottom: 5px !important;
}
/* :global(div.descriptionBoxGlossar > div.descriptionBox:last-of-type > div > div > div > div.descriptionBox:last-of-type > div p:last-of-type){
	border-bottom: 1px solid;
	padding-bottom: 8px !important;
} */
:global(div.descriptionBoxGlossar > div.descriptionBox > div > div > div > div.descriptionBox:last-of-type > hr:last-of-type){
	display: none;
}
:global(div.descriptionBoxGlossar > div.descriptionBox > div > div > div > div.descriptionBox div){
	display: block !important;
}
:global(div.descriptionBoxGlossar > div.descriptionBox > div > div > div > div.descriptionBox .icon){
	display: none !important;
}
:global(div.descriptionBoxGlossar > div.descriptionBox > div > div > div > div.descriptionBox hr){
	width: calc(100% + 14px);
}
:global(div.descriptionBoxGlossar > div.descriptionBox:last-of-type > hr:last-of-type){
	margin-top: 0px !important;
	display: none;
}
:global(div.descriptionBoxGlossar > div.descriptionBox:last-of-type > div > div > div > div.descriptionBox:last-of-type ){
	border-bottom: 1px solid;
}
/* :global(div.descriptionBoxGlossar > div.descriptionBox:last-of-type > hr:last-of-type){
	margin-top: 0px !important;
} */
/* :global(div.descriptionBoxGlossar .content){
	grid-column-start: 1;
  	grid-column-end: -1;
} */
:global(.descriptionBoxGlossar > div.descriptionBox:last-of-type > div > .content > div > div.descriptionBox:last-of-type > div > .content > .wysiwyg-wrapper > p) {
	padding-bottom: 10px !important;
}
:global(.descriptionBoxInfo div.descriptionBox:last-of-type p) {
	padding-bottom: 10px !important;
}
</style>
