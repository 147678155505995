<header>
    <div class="icon" on:click="{() => history.back()}">
        <img src="/assets/img/icons/23x23_wrong.svg" alt="">
    </div>
    {#if $translations}
	<div on:click="{() => $router.route('/catalog/filter')}"><h1>{$translations.filter}</h1></div>
    {/if}
</header>
<main>
{#if $translations}
    <div class="filter">
        <hr>
        <DropdownSelect activeColor="var(--institutional-select)" title={$translations.sort} options={sortOptions} on:selectOption="{(ev) => {sortOption = ev.detail.option} }" displayProperty="name" />
        <DropdownSelect activeColor="var(--institutional-select)" title={$translations.groupHeadline} options={groupOptions} on:selectOption="{(ev) => {groupFilter = ev.detail.option}}" displayProperty={"name"+$langId} />
        <DropdownSelect activeColor="var(--institutional-select)" title={$translations.characterFeatures} options={principleOptions} on:selectOption="{(ev) => {principleFilter = ev.detail.option} }" displayProperty={"resultText"+$langId} />
        <DropdownSelect activeColor="var(--institutional-select)" title={$translations.featuresInCatalog} options={characteristicsOptions} on:selectOption="{(ev) => {characteristicsFilter = ev.detail.option} }" displayProperty={"resultText"+$langId} />            
        <h1>
            <a href={`/catalog/${getNotEmpty(sortOption._id) || 0}/${getNotEmpty(groupFilter._id) || 0}/${getNotEmpty(principleFilter._id) || 0}/${getNotEmpty(characteristicsFilter._id) || 0}`}>
                {$translations.showResult}  
          </a>
        </h1>
    </div>
{/if}
</main>

<script>
    import {createEventDispatcher} from 'svelte';
    import DropdownSelect from '@/components/DropdownSelect.svelte';
    import {translations, groups, formPrinciples, characteristics, langId} from '@/typoStore.js';
    import {router} from '@/store.js';

    const dispatch = createEventDispatcher();

    let emptyOption = {_id: -1, name: "—", name_en: "—", name_fr: "—",
                    resultText: "—", resultText_en: "—", resultText_fr: "—"};


    let sortOptions=[emptyOption, {_id: 0, name: $translations.list}, {_id: 1, name: $translations.publishingYear}, {_id: 2, name: $translations.favorites}];
    let groupOptions;

    let sortOption = {};
    let groupFilter = {};
    let principleFilter = {};
    let characteristicsFilter = {};

    $: sortOptions=[emptyOption, {_id: 0, name: $translations.list}, {_id: 1, name: $translations.publishingYear}, {_id: 2, name: $translations.favorites}];

    $: groupOptions = $groups && $groups.length > 0 ? [emptyOption, ...$groups] : [];
    $: principleOptions = $formPrinciples ? [emptyOption, ...$formPrinciples.filter(p => p.showInFilter == true)] : [];
    $: characteristicsOptions = $characteristics ? [emptyOption, ...$characteristics.form, ...$characteristics.materialspur, ...$characteristics.technologie, ...$characteristics.verwendung] : [];

    function getNotEmpty(id) {
        return id == -1 ? 0 : id;
    }
</script>

<style lang="css">
header{
    background: var(--catalog-nav);
}
main {
	background-color: var(--catalog-main);
}
.filter{
    margin-top: 56px;
}
.filter h1{
    padding: 33.5px 14px 13px 14px;
}
header{
    grid-template-columns: 38% 1fr;
}
header div:last-of-type{
    justify-self: start;
	align-self: center;
}
</style>