import {cubicOut} from 'svelte/easing'

export function slide(node, params) {
    const existingTransform = getComputedStyle(node).transform.replace('none', '');

    return {
        delay: params.delay || 200,
        duration: params.duration || 300,
        easing: params.easing || cubicOut,
        css: (t, u) => `transform: ${existingTransform} translateX(${u*100}%)`
    };
}


export let slideSpeed = 300;

export function footerSlide(node, params) {
    // const existingTransform = getComputedStyle(node).width.replace('none', '');

    return {
        delay: params.delay || 200,
        duration: params.duration || 300,
        easing: params.easing || cubicOut,
        css: (t, u) => `width: ${t*60}px;`
        //padding-left: ${t*8}px; padding-right: ${t*8}px;`
    };
}