<!-- hi collaboration with @max -->
<header style={bgHeaderStyle}>
        <div class="icon" >
            <h1>{currentQuestionIndex+1}/{questionCount}</h1>
        </div>
		<div class="breadcrumb">
        </div>
        <div class="title">
		{#if currentQuestion}
            <h1>{currentQuestion['headline'+$langId]}</h1>
		{/if}
        </div>
</header>
<main>
	<div in:slide="{{duration: animateTransition ? slideSpeed : 1}}">
		<div class="play">
			{#if answers}
				{#each answers as answer}
					{#if answer && answer.identifyImages}
					<div class="imageContainer">
						<ImageBox 
							isRightAnswer={!answer.isWrong} 
							isAnswered={showSolution}
							img={base+answer.identifyImages[Math.floor(Math.random()*answer.identifyImages.length)].path} 
							isQuestion={true} 
							on:click="{(ev) => answerQuestion(answer)}"/>
					</div>
					{/if}
				{/each}
			{/if}
			{#if currentQuestion}
				<h1>{currentQuestion['question'+$langId]}</h1>
			{/if}
		</div>
	</div>
</main>
<script>
import {onMount} from 'svelte';
import {slide, slideSpeed} from '@/transitions.js';
import ImageBox from '@components/ImageBox.svelte';
import {questions, typefaces, base, langId} from '@/typoStore.js';
import {variableIdentifier, router} from '@/store.js';

export let animateTransition;

let questionRound;
let currentQuestion;
let currentQuestionIndex = 0;
let showSolution = false;
let questionCount = 20;
let answers = [];
let rightAnswers = 0;

$: bgHeaderStyle =  `background-color: var(--${$variableIdentifier}-nav)`;
$: currentQuestion = questionRound ? questionRound[currentQuestionIndex] : null;
$: if($questions && $questions.length > 0) questionRound = getRandomQuestions(questionCount);
$: answers = getAnswers(currentQuestionIndex);
$: if(currentQuestionIndex != 0) saveGame(currentQuestionIndex, questionCount, rightAnswers);
$:if(currentQuestionIndex >= questionCount) {
			$router.route('/play/result');
		}

$: getAnswers = (index) => {
	if(currentQuestion == null || $typefaces == null) return ['loading'];

	let faces = $typefaces;
	let wrongAnswers = [];
	for(let a = 0; a < currentQuestion.wrongAnswers.length; a++) {
		for(let i = 0; i < faces.length; i++) {
			checkForMatchingValues(faces[i], currentQuestion.wrongAnswers[a], wrongAnswers);
		}
	}
	wrongAnswers.forEach(a => a.isWrong = true);
	let rightAnswers = [];	
	for(let a = 0; a < currentQuestion.rightAnswers.length; a++) {
		for(let i = 0; i < faces.length; i++) {
			checkForMatchingValues(faces[i], currentQuestion.rightAnswers[a], rightAnswers);
		}
	}
	rightAnswers.forEach(a => a.isWrong = false);

	wrongAnswers = shuffle(wrongAnswers);
	wrongAnswers = wrongAnswers.slice(0,2);
	let tempAnswers = shuffle([...wrongAnswers, rightAnswers[Math.floor(Math.random()*(rightAnswers.length-1))]]);

	return tempAnswers ? tempAnswers : [];
}

function saveGame(index, count, correct) {
	localStorage.setItem('typo_playStats', JSON.stringify({round: index, rightAnswers: correct, totalRounds: count}));

}

onMount(() => {
	answers = getAnswers(currentQuestionIndex);
	let savedGame = JSON.parse(localStorage.getItem('typo_playStats'));
	if(savedGame) {
		currentQuestionIndex = savedGame.round || 0;	
		rightAnswers = savedGame.rightAnswers || 0;	
	}		
})

function checkForMatchingValues(obj, match, matches) {
	let vals = Object.values(obj);
	vals.forEach(tval => {
		if(Array.isArray(tval)) {
			tval.forEach(el => {
				if (matchId(el, match)) 
					matches.push(obj) ;
			});
		}
		else {
			if (matchId(tval, match)) 
				matches.push(obj);
		}
	});	
}

function matchId(value, match) {

	if(typeof value === 'object' && value !== null) {
		if(value._id != null && value._id == match._id) {
			return true;
		}	
	}
	return false;
}

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}


function getRandomQuestions(amount) {
	let i = 0;
	let indices = [];
	// if (amount >= $questions.length) return;
	while (i < amount) {
		let index = Math.floor(Math.random() * ($questions.length-1));	
		indices.push(index);
		i++;
	}
	let tempQuestions = [];
	indices.forEach(index => {
		let question = $questions[index];
		if(question != null)
			tempQuestions.push(question);
	});
	return tempQuestions;
}

function answerQuestion(answer) {

	showSolution = true;
	if(!answer.isWrong) {
		rightAnswers = rightAnswers + 1;
	}

	setTimeout(() => {
		showSolution = false;
		currentQuestionIndex = currentQuestionIndex + 1;
		
	}, 2200);
}
	
</script>

<style lang="css">
main {
	background-color: var(--play-main);
}
.play > h1:first-of-type{
	padding: 14px;
}
</style>
