<header>
	<div class="icon" on:click="{() => $router.route('/catalog/filter')}">
		<img src="/assets/img/icons/31x31_search.svg" alt="">
	</div>
	<div class="icon" on:click="{() => isListViewExtended = !isListViewExtended}">
		{#if isListViewExtended}
			<img src="/assets/img/icons/23x22_list1.svg" alt="">
		{:else}
			<img src="/assets/img/icons/23x22_list2.svg" alt="">
		{/if}
	</div>
	<div class="title"><h1>{getFilterText()}</h1></div>
</header>
<main>
	<ul class="alphabetList" >
		{#each letters as letter}
			<li on:pointerenter="{() => scrollToFirstOf(letter)}" on:click|stopPropagation>{letter}</li>
		{/each}
		
	</ul>
	<div class="catalogWrapper">
		<div in:slide="{{duration: animateTransition ? slideSpeed : 1}}">
			<!-- 
				<CatalogFilter 
				on:sortOption="{(ev) => setSort(ev.detail)}" 
				on:groupFilter="{(ev) => filterGroup = ev.detail.option}"
				on:principleFilter="{(ev) => filterPrinciple = ev.detail.option}"
				on:characteristicsFilter="{(ev) => filterCharacteristic = ev.detail.option}"
				/> 
			-->
			<div>
				{#if $typefaces}
					{#each sortedTypeFaces as face}
						<CatalogItem 
							id={face._id} 
							name={face.name} 
							year={face.year} 
							showImage={isListViewExtended} 
							imgSrc={base+face.catalogImages.oneLine.path} 
							isStarred={$starredTypefaces ? $starredTypefaces.includes(face._id) : false}
							on:click={() => $router.route(`/typefaces/${face._id}`)} />
					{/each}
				{/if}
				<hr>
			</div>
		</div>
	</div>
</main>


<script>
import {onMount} from 'svelte';
import {typefaces, base, characteristics, groups, formPrinciples, starredTypefaces, language, langId, translations} from '@/typoStore.js';
import CatalogItem from '@components/CatalogItem.svelte';
import Footer from '@components/Footer.svelte';
import {variableIdentifier, router} from '@/store.js';
import {slide, slideSpeed} from '@/transitions.js';

import { scrollto } from 'svelte-scrollto';

export let id;
let isListViewExtended;
export let animateTransition;
let filteredTypeFaces = [];
let sortedTypeFaces = [];

export let filterGroup = null;
export let filterPrinciple = null;
export let filterCharacteristic = null;
export let sortOption = 0;

// let sortByName = true;
const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

onMount(() => {
	let savedView = JSON.parse(localStorage.getItem("typoapp_catalogExtendeView"));
	isListViewExtended = savedView;
});

function getFilterText() {};

$: filterGroup, filterPrinciple, sortOption, langId, translations, getFilterText = () => {
	let resultText = '';
	if($typefaces && $formPrinciples && $groups) {
		if(sortOption != null) {
			if(sortOption == 0)
				resultText = $translations.list;
			else if(sortOption == 1)
				resultText = $translations.publishingYear;
			else 
				resultText = $translations.favorites;
		}
		if(filterGroup != null && filterGroup != 0) {
			resultText = $groups.find(t => t._id == filterGroup)['name'+$langId];
		}
		if(filterPrinciple != null && filterPrinciple != 0) {
			resultText = $formPrinciples.find(t => t._id == filterPrinciple)['name'+$langId];
		}
		if(filterPrinciple != null && filterPrinciple != 0 && filterGroup != null && filterGroup != 0)
			resultText = $groups.find(t => t._id ==filterGroup)['name'+$langId] + ', ' + $formPrinciples.find(t => t._id ==filterPrinciple)['name'+$langId];
	}
	return resultText;
	

}

$: characteristicsArray = $characteristics ? [...$characteristics.form, ...$characteristics.materialspur, ...$characteristics.technologie, ...$characteristics.verwendung] : [];


$: bgHeaderStyle =  `background-color: var(--${$variableIdentifier}-nav)`;
$: bgVariable = `background-color: var(--${$variableIdentifier}-main)`;

$: {
	if(isListViewExtended != null) localStorage.setItem('typoapp_catalogExtendeView', JSON.stringify(isListViewExtended));
} 
// $: sortByName = sortOption == 0;


$: filteredTypeFaces = $typefaces ? $typefaces.filter(font => {
	let isMatch = true;
	if(filterGroup != null && filterGroup != 0)
		isMatch = font.group._id == filterGroup;
	if(filterPrinciple != null && filterPrinciple != 0 && font.formprinzip)
		isMatch = isMatch && font.formprinzip._id == filterPrinciple;
	if(filterCharacteristic != null && filterCharacteristic != 0){
		let typeChars = [];
		if(font.form != null && font.form != "") typeChars = Array.isArray(font.form) ?  [...font.form] : [font.form];
		if(font.verwendung != null && font.verwendung != "") typeChars = Array.isArray(font.verwendung) ? [...typeChars, ...font.verwendung] : [...typeChars, font.verwendung];
		if(font.materialspur != null && font.materialspur != "") typeChars = Array.isArray(font.materialspur) ? [...typeChars, ...font.materialspur]: [...typeChars, font.materialspur];
		if(font.technologie != null && font.technologie != "") typeChars = Array.isArray(font.technologie) ? [...typeChars, ...font.technologie] : [...typeChars, font.technologie];
		isMatch = isMatch && typeChars.find(c => c._id == filterCharacteristic) != null;
	}

	return isMatch;
}) : [];

$: sortedTypeFaces = filteredTypeFaces.sort((a, b) => {
	if(sortOption == 0) 
		return compareByName(a,b);	
	else if(sortOption == 1) 
		return compareByYear(a,b);
	else if(sortOption == 2)
		return compareByNameAndFavorites(a,b) 
})


function scrollToFirstOf(letter) {
	let firstType = sortedTypeFaces.find(t => t.name.startsWith(letter));
	let firstEl = document.getElementById(firstType._id);
	if(firstEl != null)
		firstEl.scrollIntoView();

} 

function enableScrolling(enable) {
	document.body.style.overflowY = enable ? 'auto' : 'hidden';
}

function compareByName(a, b) {
	if (a.name.toUpperCase() > b.name.toUpperCase())
		return 1;
	if (a.name.toUpperCase() < b.name.toUpperCase())
		return -1;
	return 0;
}

function compareByNameAndFavorites(a, b) {
	if($starredTypefaces.includes(a._id))
		return -1;
	if($starredTypefaces.includes(b._id))
		return 1;
	if (a.name.toUpperCase() > b.name.toUpperCase())
		return 1;
	if (a.name.toUpperCase() < b.name.toUpperCase())
		return -1;
	return 0;
}

function compareByYear(a, b) {
	if (a.year < b.year)
		return 1;
	if (a.year > b.year)
		return -1;
	return 0;
}

function setSort(detail) {
	sortOption = detail.option;
	let i = detail.index;
	if (i == 0) 
		sortByName = true;
	else if (i == 1)
		sortByName = false;
}



</script>

<style lang="css">
header{
    background: var(--catalog-nav);
}
main {
	background-color: var(--catalog-main);
}
header{
	grid-template-columns: 12% 26% 1fr
}
header div:last-of-type{
	justify-self: start;
	align-self: center;
}

header > .icon:first-child {
    width: 24px;
    height: 24px;
    margin: 14px 0px 4px 14px;
}
header > .icon {
    margin: 14px 0px 14px 7px;
}
ul.alphabetList{
	width: 20px;
    height: 360px;
    position: fixed;
    right: 3px;
    top: calc(50% - 180px);
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    z-index: 10;
    touch-action: none;
}
:global(.catalogWrapper){
	margin-top: 56px;
	width: calc(100vw - 15px);
}
</style>
