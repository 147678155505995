<header>
    <div class="icon" on:click="{()=> $router.route('/glossar')}">
        <img src="assets/img/icons/31x23_glossar.svg" alt="">
    </div>
	<div class="breadcrumb">
    </div>
    <div class="title">
        <h1>{$translations.groupInCatalog}</h1>
    </div>
</header>
<main>
	<div in:slide="{{duration: animateTransition ? slideSpeed : 1}}">
		<div class="groupContainer">
			{#if $groups}
				{#each $groups as group}
					<ImageBoxSmall activeColor="var(--learn-select)" name={group['name'+$langId] }
						img={base+group.learnImages[Math.floor(Math.random()*group.learnImages.length)].path} 
						on:click="{() => $router.route(`/learn/group/${group._id}`)}"
						/>
				{/each}
			{/if}
		</div>
	</div>
</main>

<script>
import {groups, base, langId, translations} from '@/typoStore.js';
import {variableIdentifier, router} from '@/store.js';
import {slide, slideSpeed} from '@/transitions.js';

import ImageBoxSmall from '@/components/ImageBoxSmall.svelte';
export let animateTransition;



</script>

<style lang="css">

header {
	background-color: var(--learn-nav);
}
main {
	background-color: var(--learn-main);
}
.groupContainer{
	margin-top: 56px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

</style>
