<div class="breadcrumb">
    {#each {length: count} as _, i}
        {#if activeIndex == i}
         <img src="/assets/img/icons/7x7_swipe-indicator.svg" alt="">
         {:else}
         <img src="/assets/img/icons/5x5_swipe-indicator.svg" alt="">
         {/if}
    {/each}
</div>

<script>

export let count = 5;
export let activeIndex = 0;


</script>