<div class="app">
	{#if showPopup === true}
		<VideoPopup on:close={() => showPopup = false} videoUrl={$contentVersion.video.path}></VideoPopup>
		<!-- <VideoPopup on:close={() => showPopup = false} videoUrl="https://raw.githubusercontent.com/mediaelement/mediaelement-files/master/big_buck_bunny.mp4"></VideoPopup> -->
	{:else}
		<svelte:component this={ Route } { ...params } {animateTransition}/>	
		<Footer/>
	{/if}

</div>

<script>
	import Navaid from 'navaid';
	import { onMount } from 'svelte';
	import Footer from '@components/Footer';
	import Header from '@components/Header';
	import Identify from '@pages/Identify';
	import IdentifySubpage from '@pages/IdentifySubpage';
	import IdentifyCharacteristics from '@pages/IdentifyCharacteristics';
	import IdentifyResult from '@pages/IdentifyResult';
	import Learn from '@pages/Learn';
	import LearnGroup from '@pages/LearnGroup';
	import LearnSubgroup from '@pages/LearnSubgroup';
	import Play from '@pages/Play';
	import PlayResult from '@pages/PlayResult';
	// import Institution from '@pages/Institution';
	import CatalogFilter from '@pages/CatalogFilter';
	import Glossar from '@pages/Glossar';
	import Catalog from '@pages/Catalog';
	import TypefaceDetail from '@pages/TypefaceDetail.svelte';
	import Info from '@pages/Info';
	import {windowPath, variableIdentifier, router} from '@/store.js';
	import smoothscroll from 'smoothscroll-polyfill';
	import {contentVersion} from '@/typoStore.js';
	import VideoPopup from '@pages/VideoPopup';


	let Route, params = {};
	let animateTransition = false;
	let showPopup = false;

	$: bgVariable = `background-color: var(--${$variableIdentifier}-main)`;

	$: if($contentVersion) {
		let storedVersion = localStorage.getItem('typo_contentVersion');
		if($contentVersion.enabled == true && (!storedVersion || Number.parseInt($contentVersion.versionNumber) > Number.parseInt(storedVersion))) {
			showPopup = true;
		}
		localStorage.setItem('typo_contentVersion', JSON.stringify($contentVersion.versionNumber));
	}

	onMount(() => {
		console.log(VERSION);

	})

	function draw(m, pars) {

		params = pars || {};
		Route = m.default || m;
		Route = Route;
		$windowPath = window.location.pathname;

	}

	function track(obj) {
		if(obj.type === "popstate") { 
			animateTransition = false;
		}
		else {
			animateTransition = true;
		}
		// $windowPath = obj.uri || obj.state;
	}
	addEventListener('replacestate', track);
	addEventListener('pushstate', track);
	addEventListener('popstate', track);

	$router = (
		Navaid('/')
			.on('/', () => $router.route('/learn', true))
			.on('/identify', () => draw(Identify,{}))
			.on('/identify/result', () => draw(IdentifyResult, {}))
			.on('/identify/characteristics', () => draw(IdentifyCharacteristics, {}))
			.on('/identify/:detectionIndex', (obj) => draw(IdentifySubpage,obj))	
			.on('/learn', () => draw(Learn,{}))
			.on('/learn/group/:groupId', obj => draw(LearnGroup, obj))
			.on('/learn/subGroup/:groupId', obj => draw(LearnSubgroup, obj))
			.on('/play', obj => draw(Play, obj))
			.on('/play/result', obj => draw(PlayResult, obj))
			.on('/catalog', () => draw(Catalog))
			.on('/catalog/filter', () => draw(CatalogFilter))
			.on('/catalog/:sortOption/:filterGroup/:filterPrinciple/:filterCharacteristic', (obj) => draw(Catalog, obj))
			.on('/typefaces/:id', (p) =>  draw(TypefaceDetail, p))
			.on('/institution', () => draw(Institution, {}))
			.on('/glossar/:entryId?', (obj) => {
				if(obj.entryId) obj.openDefaultState = false;
				draw(Glossar, obj);
			})
			.on('/info',() => draw(Info,{}))
	);

	onMount(() => {
		smoothscroll.polyfill();
		$router.listen();


		return () => {
			$router.unlisten();
		};
	});
</script>

<style>
/* :global(main){
    width: 100%;
    min-height: calc(100vh - 132px);
    padding-bottom: 44px; 
    top: 44px;
    position: absolute;
    background-color: var(--erkennen-main);
    z-index: 0;
} */
</style>
