import App from '@components/App';
import './index.css';

window.app = new App({
	target: document.querySelector('#app')
});

if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) { 
	if (process.env.NODE_ENV === 'production') {
		// Service Worker registration
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('/sw.js',{
				updated (registration) {
					if (confirm(`New content is available!. Click OK to refresh`)) {
						window.location.reload();
					}
				}
			});
		}
	}
}

else {
	if(!inIframe())
    	window.location.assign("https://" + window.location.hostname + "/desktop");
} 

function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}


