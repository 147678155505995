<header>
        <div class="icon big" on:click="{()=> $router.route('/play')}">
            <img src="/assets/img/icons/23x28_reload.svg" alt="">
        </div>
        <div class="breadcrumb">
        </div>
</header>
<main>
    {#if $translations}
    <div class="resultBox">
        <hr>
        <!-- <div class="result">
            <div>
               <h1>00:50 Minuten</h1>
            </div>
            <div>
                <img src="/assets/img/icons/20x19_star.svg" alt="">
                <img src="/assets/img/icons/20x19_star_inactive.svg" alt="">
                <img src="/assets/img/icons/20x19_star_inactive.svg" alt="">
                <img src="/assets/img/icons/20x19_star_inactive.svg" alt="">
                <img src="/assets/img/icons/20x19_star_inactive.svg" alt="">
            </div>
        </div>
        <hr> -->
        <div class="result">
            <div>
               {#if result}
               <h1>{result.rightAnswers} {$translations.correctAnswers}</h1>
               {/if}
            </div>
            <div>
                {#each {length: 5} as _, index}
                    {#if result && index < (result.rightAnswers / result.totalRounds) * 5}
                        <img src="/assets/img/icons/20x19_star.svg" alt="">
                    {:else}
                        <img src="/assets/img/icons/20x19_star_inactive.svg" alt="">
                    {/if}
                {/each}
            </div>

        </div>
        <hr>
        <div>
            {#if result && $playResultInfo}
                <WYSIWYG content={(result.rightAnswers / result.totalRounds) * 5 > 2 ? getRandomText($playResultInfo['winning'+$langId]) : $playResultInfo['losing'+$langId] } />
            {/if}
        </div>
        <hr>
        <div>
            <h1>
                <a href="/play">
                   {$translations.newGame}
                </a>
            </h1>
        </div>

    </div>
    {/if}
</main>
<script>

import {onMount} from 'svelte';
import { subGroups, langId, translations, playResultInfo } from '@/typoStore.js';
import {variableIdentifier, router} from '@/store.js';
import WYSIWYG from '@components/WYSIWYG.svelte';

let result;

onMount(() => {    
    result = JSON.parse(localStorage.getItem('typo_playStats'));
    if(result.rightAnswers == null) $router.route(`/play`);
    localStorage.setItem('typo_playStats', JSON.stringify({round: 0}));
    
})

function getRandomText(textArray) {
    if(!textArray) return "";
    let id =  Math.floor(Math.random() * (textArray.length - 1));
    return textArray[id].value;

}

</script>

<style>
header {
	background-color: var(--play-nav);
}
main {
	background-color: var(--play-main);
}
.icon.big img{
    max-height: 120%;
    margin-top: -3px;
}
.resultBox > .result{
    display: grid;
    padding: 11.5px 14px 13px 14px;
    grid-template-columns: 1fr 1fr;
}
.resultBox > div:not(.result){
    padding: 11.5px 14px 13px 14px;
}
.result{
    margin-bottom: 44px;
}
.result div{
    justify-self: start;
    align-self: center;
    height: 24px;
}
.result > div:last-of-type{
    justify-self: end;
}
.result div img{
    height: 16px;
    padding-left: 4px;
}
.resultBox{
    margin-top: 56px;
}
</style>