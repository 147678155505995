<script>
import {tick, createEventDispatcher} from 'svelte';

export let title;
export let content;
export let hideArrow = false;
export let showContent = false;
export let scrollToElement = false;
export let childIsAnimating = false;
export let animateTransition = true;
export let id = 0;
export let active = false;
export let activeColor = "var(--play-select)";
export let backgroundColor = "transparent";
export let maxHeight = 2000;
export let inFocus = false;
let styleString = "";


const dispatch = createEventDispatcher();

let headline;
let contentWrapper;
let contentHeight = 0;

let isActive = true;
let showContentString = "max-height: 2000px";

$: styleString = `background-color: ${inFocus ? activeColor : backgroundColor}`
$: showContentString = `max-height: ${showContent ? maxHeight : 0}px`;


$: if(showContent && headline && scrollToElement) setTimeout(() => {
    let element = document.getElementById(id);
    let behavior = 'smooth';
    let scrollTop = window.pageYOffset || element.scrollTop

   // Furthermore, if you have for example a header outside the iframe 
   // you need to factor in its dimensions when calculating the position to scroll to
    // const headerOutsideIframe = window.parent.document.getElementsByClassName('myHeader')[0].clientHeight

    const finalOffset = element.getBoundingClientRect().top + scrollTop - 100;
    

    window.scrollTo({
        top: finalOffset,
        behavior: behavior || 'auto'
    })
//   document.getElementById(title).scrollIntoView({behavior: "smooth",inline: "nearest"}), 500);;

}, 500);
$: if(showContent == true) {
     tick().then(() => updateContentSize());
     inFocus = true;
}
else if (showContent == false) {
    inFocus = false;
}

$: if(childIsAnimating === true) animateContentUpdate();

export function updateContentSize() {  
        if(contentWrapper) contentHeight = contentWrapper.firstChild.scrollHeight;
}

const updateMe = () => {        
    // console.log("content size updated");
    updateContentSize();
}

function animateContentUpdate() {
    // if(childIsAnimating) {
    //     requestAnimationFrame(animateContentUpdate);    
    // }
    // updateContentSize();

}


export function forceAnimationUpdate(enable) {
    childIsAnimating = enable;
}

</script>
{#if title != null}
<div class="descriptionBox" id={id} style={styleString}>
    <div>
        <h1 bind:this={headline} on:click="{() => showContent = !showContent}">{title}</h1>
        <div class="icon" class:active="{showContent}" on:click="{() => showContent = !showContent}">
            {#if !hideArrow}
                <img src="/assets/img/icons/16x11_arrow.svg" alt="">
            {/if}
        </div>
       
        <div bind:this={contentWrapper} class="content" style={showContentString} class:animateTransition>    
                <!-- <div bind:this={contentWrapper} class="content" on:transitionend on:transitionstart class:showContent class:animateTransition style={`height: ${showContent ? contentHeight : 0}px`}>     -->

            <slot {updateMe}></slot>
        </div>
        
    </div>
    <hr>
</div>
{/if}

<style>
.descriptionBox > div{
    display: grid;
    grid-template-columns: auto max-content;
    padding: 11.5px 14px 0px 14px;
}
.descriptionBox > div > h1{
    padding-bottom: 11.5px;
}
.animateTransition {
    transition: max-height 300ms;
}
.content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 300ms cubic-bezier(0.740, -0.025, 0.225, 1.020);;
}
:global(.content > div:not(.synonymBoxInner) > p){
    padding-bottom: 13px;
}

.showContent {
    max-height: 2000px;
}

</style>