<header>
        <div class="icon" on:click="{()=> $router.route('/glossar')}">
            <img src="/assets/img/icons/31x23_glossar.svg" alt="">
        </div>
  		<Breadcrumb count=5 activeIndex=0 />

		<div class="title">
            <h1>{$translations.groupHeadline}</h1>
        </div>
</header>
<main>
	<div in:slide="{{duration: animateTransition ? slideSpeed : 1}}">
	{#if $identifyGroups}
		{#each $identifyGroups as group, index}
			<ImageBox on:click="{() => startPath(group.detectionPath, group)}" img={getRandomImage(group, $typefaces)} activeColor="var(--identify-select)" text={group['name'+$langId]} />
		{/each}
	{/if}
	</div>
</main>

<script>
import ImageBox from '@/components/ImageBox.svelte';
import Breadcrumb from '@/components/Breadcrumb.svelte';
import {slide, slideSpeed} from '@/transitions.js';
import {variableIdentifier, router} from '@/store.js';
import {identifyGroups, translations, base, typefaces, langId} from '@/typoStore.js';

export let animateTransition;


function startPath(detectionPath, group) {
	localStorage.setItem('typo_detectionPath', JSON.stringify(detectionPath));
	localStorage.setItem('typo_selectedOptions', JSON.stringify([group]));
	$router.route('/identify/0/');
}

function getRandomImage(obj, src) {
	if(src) {
		let results = src.filter(t => t.identifyGroup._id == obj._id);

		if(results && results.length > 0) {
			let randomIndex = Math.floor(Math.random()*(results.length-1));
			let randomTypeface = results[randomIndex];

			if(randomTypeface.identifyImages)
				return base + randomTypeface.identifyImages[Math.floor(Math.random()*(randomTypeface.identifyImages.length-1))].path;
		}
	}
	return "";
	// base + group.images
}

</script>

<style lang="css">
header {
	background-color: var(--identify-nav);
}
main {
	background-color: var(--identify-main);
}
</style>
