<header>
    <div class="icon" on:click="{() => history.back()}">
		<img src="/assets/img/icons/23x23_wrong.svg" alt="">
	</div>
	<div class="breadcrumb">
    </div>
    <div class="title">
        <h1>{$translations.information}</h1>
    </div>
</header>
<main>
	<div class="descriptionBoxInfo" in:slide="{{duration: animateTransition ? slideSpeed : 1}}">
	<hr>
	{#if $publishedLanguages && $translations.settings}
		<Dropdown activeColor="var(--learn-select)" title={$translations.settings} hideArrow={false}>
			<div>
				{#each Object.values($publishedLanguages) as lang}
				{#if lang.name && lang.published}
					<p on:click={() => $language = lang.id}>{lang.name}</p>
				{/if}
				{/each}
			</div>		
		</Dropdown>
	{/if}

	{#each entries as entry, i (entry.value.header)}
		{#if entry['value']}
		<Dropdown activeColor="var(--learn-select)" title={entry.value.header} hideArrow={false}>
			<WYSIWYG content={entry.value.description}/>
		</Dropdown>
		{/if}
	{/each}
	<Dropdown activeColor="var(--learn-select)" title="Version">
		<p>{VERSION}</p>
	</Dropdown>
	
	</div>
</main>

<script>
import {tick} from 'svelte';
import Dropdown from '@components/Dropdown.svelte';
import WYSIWYG from '@components/WYSIWYG.svelte';
import {institutional, langId, translations, language, publishedLanguages} from '@/typoStore.js';
import {slide, slideSpeed} from '@/transitions.js';
export let entryId;
export let animateTransition;

let entries = [];

let glossarGroups = {};

$: if($institutional && $langId != null) entries = $institutional['entries'+$langId];

</script>

<style lang="css">
header{
	background-color: var(--learn-nav);
}
main {
	background-color: var(--learn-main);
}
main > div:first-child{
	margin-top: 56px;
}
:global(.descriptionBoxInfo > .descriptionBox > div > div > div > .descriptionBox > div){
	padding-left: 0 !important;
	padding-right: 0 !important;
}

:global(.descriptionBoxInfo > .descriptionBox > div > div > div > .descriptionBox > hr){
	margin-left: 0 !important;
}
:global(.descriptionBoxInfo > .descriptionBox > div > div > div > hr){
	margin-left: 0 !important;
	width: calc(100% - 28px);
	position: absolute;
}
:global(.descriptionBoxInfo > .descriptionBox > div > h1){
	padding-bottom: 11.5 !important;
}
:global(.descriptionBoxInfo .wysiwyg-wrapper > p:last-of-type){
	padding-bottom: 11.5px !important;
}
</style>
