<script>
import {router} from '@/store.js';
import {base} from '@/typoStore.js';
import { createEventDispatcher, onMount, tick } from 'svelte'; 
export let content;

const dispatch = createEventDispatcher();

let element;
let isLoaded = false;

// const updateDispatch = () => {
//  dispatch('contentloaded', 'test');
// }

function loaded(e) {
    dispatch('loadedcontent', {});
}

$: if(isLoaded == true) dispatch('loadedcontent', {});

$: if(content) isLoaded = false;


$: if(element && content) {
    tick().then(() => {
       
    element.querySelectorAll('a').forEach(a => {
        
        a.href = a.href.replace('collection://', '/');
        // a.href += "/#5de16df13630616aa7000224";
        // '/glossar/#23904857'
        
    });
    let images = element.querySelectorAll('img');
    if(images.length > 0) {
        images.forEach(a => {
            a.src = base+a.getAttribute('src');
            a.classList.add('wysiwygImage');
            a.onload = loaded;
        });
    }
    else {
        loaded();
    }
    })
    
}

// function handleClick(ev) {
//     if(ev.target && ev.target.href) {
//         let link = ev.target.href;
//         let matchExpr = new RegExp('^collection://');
//         if(link.match(matchExpr)) {
//             ev.preventDefault();
//             console.log("clicked a collection link!");
//             $router.route(ev.target.pathname.slice(1, ev.target.pathname.length));       
//         }
//     }
// }

</script>

<!-- {#if content != null} -->
<div bind:this={element} class="wysiwyg-wrapper" >{@html content}</div>
<!-- {/if} -->

<style>
:global(.wysiwygImage) {
    width: 100%;
}


</style>