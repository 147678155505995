
<a href={path}>
    <div class="nav_wrapper" class:active={urlActive}>
        <img style={`height: ${height}`} on:click="{() => window.setTimeout(() => showForSomeTime(), 50)}" src={img} alt="">
        <p class:show style={`overflow: hidden`}>{name}</p>
    </div>
</a>

<script>

import {windowPath} from '@/store.js';

export let path = "";
export let name = "";
export let img = "";
export let height = "100%";

$: urlActive = checkURLActive($windowPath,path);
$: if(urlActive === false) {
    show = false;
    clearTimeout(showTimeout);
}

let show = false;
let showTimeout;

function showForSomeTime() {
    showTimeout = setTimeout(() => show = false, 2000);
    show = true;
}

function checkURLActive(url, match) {
    if(url) {
        return url.startsWith(match) || (url.startsWith('/typefaces') && match == '/catalog');
    }
    else return false;
}


</script>

<style>
.nav_wrapper{
    list-style: none;
    height: 36px;
    width: auto;
    position: relative;
	padding: 4px;
    opacity: 0.5;	
    white-space: nowrap;
}

.nav_wrapper.active {
	opacity: 1;
}

.nav_wrapper > img{
    /* height: 99%; */
    float:left;
    position: relative;
}

.nav_wrapper > p {
    position: relative;
	display: inline-block;
    width: 0;
    font-family: 'Infini-bold';
	font-weight: normal;
    margin: 8px 0px 8px 4px;
    white-space: nowrap;
    color: rgba(0,0,0,0);
    transition: all 500ms ease;
}

.nav_wrapper > p.show {
	/* display: block; */
    width: 70px;
    color: rgba(0,0,0,1);
}

</style>