
<header>
	<div class="icon" on:click={() => window.history.back()}>
		<img src="/assets/img/icons/23x23_wrong.svg" alt="">
	</div>
</header>

<main>
    <div class="typefaceDetailWrapper" in:slide="{{duration: animateTransition ? slideSpeed : 1}}">

        <div class="wysiwyg">
            <hr>
            <div on:click|stopPropagation="{() => starredTypefaces.star(id)}">
                {#if isStarred}
                    <img src="/assets/img/icons/20x19_star.svg" alt="">
                {:else}
                    <img src="/assets/img/icons/20x19_star_inactive.svg" alt="">
                {/if}
            </div>
            {#if typeface}
                <h1>{typeface.name}</h1>
            {/if}
            {#if typeface && typeface.catalogImages}
                <SvokZoom backgroundColor="white" up={false} img={base+typeface.catalogImages.multiLine.path} />
                <!-- <img src={base+typeface.catalogImages.multiLine.path} alt="" /> -->
            {/if}  
        </div>

        <div class="synonymBox">
        <hr>
        {#if typeface && $translations}
            <div>  
            <div></div>
                <div></div>
                <div class="synonymBoxInner">
                    <h1>{$translations.design}</h1><p>{typeface.design}</p>
                    <h1>{$translations.foundry}</h1><p>{typeface.foundry}</p>
                    <h1>{$translations.publishingYear}</h1><p>{typeface.year}</p>
                    <h1>{$translations.groupHeadline}</h1><p>{typeface.subgroup['name'+$langId]}</p>
                </div>
            </div>
         {/if}
        </div>
        {#if typeface && typeface['wysiwyg'+$langId]}
        <WYSIWYG content={typeface['wysiwyg'+$langId]} />
        <hr>
        <img src={base + typeface.catalogImages.letter.path} alt=""/>
        {/if}
    </div>
</main>

<script>
    import {onMount} from 'svelte';
    import {typefaces, translations, base, langId, starredTypefaces} from '@/typoStore.js';
    import { variableIdentifier } from '@/store.js';
    import {slide, slideSpeed} from '@/transitions.js';
    import SvokZoom from '@components/SvokZoom.svelte';
    import WYSIWYG from '@components/WYSIWYG.svelte';
    export let id;
    export let animateTransition;
    
    export let isStarred = false;



    let typeface;

    onMount(() => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
            });
    })


    $: typeface = $typefaces ? $typefaces.find(face => face._id === id) : null;
    $: bgHeaderStyle =  `background-color: var(--${$variableIdentifier}-nav)`;
    $: if($starredTypefaces) isStarred = $starredTypefaces.includes(id);


</script>

<style>
header {
    background: var(--catalog-nav);
}
main {
    background-color: var(--catalog-main);
}

.synonymBox > div {
    display: grid;
    padding: 11.5px 14px 0px 14px;
    grid-template-columns: auto max-content;
}

.synonymBoxInner {
    display: grid;
    grid-template-columns: 3fr 9fr;
    padding-bottom: 18px;
}

.wysiwyg > h1{
    padding: 11.5px 14px 0px 14px;
    width: calc(100% - 148px) !important;
}
.wysiwyg > div{
    position: absolute;
    width: 16px;
    padding: 14px;
    right: 0;
    z-index: 10;   
}
.wysiwyg > div > img{
    width: 100%;
}

.typefaceDetailWrapper{
    margin-top: 56px;
}
:global(.typefaceDetailWrapper > .wysiwyg-wrapper){
    padding: 0px 14px 13px 14px;
}

/* :global(.zoomcontainer){
	grid-template-columns: 1fr 24px !important;
	width: calc(100% - 14px) !important;
} */
:global(.zoom_icon){
    position: relative;
    /* height: 100px; */
}
:global(.zoom_icon > img){
    padding: 0 0 14px 0 !important;
    position: absolute;
    left: 0;
    bottom: 0;
}
.typefaceDetailWrapper img{
    width: 100%;
}
</style>