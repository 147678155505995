<header>
        <div class="icon big" on:click="{()=> $router.route('/identify')}">
            <img src="/assets/img/icons/23x28_reload.svg" alt="">
        </div>
        <div class="breadcrumb">
        </div>
</header>
<main>
    <div class="resultBox">
        <hr>
        <div class="result">
            <h1>{$translations.result}</h1>
            {#if result}
            <a href={`/learn/subGroup/${result._id}`}>
                <h1>{result['name'+$langId]}</h1>      
            </a>
            {/if}
        </div>
        <hr>
        <div class="result" style={selectedOptions.length < 3 ? `margin-bottom: 40px` : `margin-bottom:20px`}>
        {#if selectedOptions.length > 0}
            <h1>{$translations.groupHeadline}</h1>          
            <p>{selectedOptions[0]['resultText'+$langId]}</p>
            {#each detectionPath as step, index}
                {#if step['name'] != "Serifen"}
                <h1>{step['headline'+$langId]}</h1>         
                <p>{selectedOptions[index+1]['resultText'+$langId]}</p>  
                {/if}
            {/each}
            <!-- 
                <h1>Strichkontrast</h1>         
                <p>{result.strichkontrast.name}</p>         
                <h1>Formprinzip</h1>          
                <p>{result.formprinzip.name}</p> 
            -->
        {/if}
        </div>   
        {#if Object.keys(selectedCharacteristics).length > 0}
            <hr>
            <div> 
            <h1>{$translations.featuresHeadline}</h1>
            {#each Object.values(selectedCharacteristics) as characteristic}
                {#if characteristic._id != -1}
                <p>{characteristic['name'+$langId]}</p>
                <p></p>
                {/if}
            {/each}
            </div> 
        {/if} 
    </div>
</main>

<script>

import {onMount} from 'svelte';
import { subGroups, langId, translations } from '@/typoStore.js';
import {variableIdentifier, router} from '@/store.js';

let result;

let selectedOptions = [];
let detectionPath;
let selectedCharacteristics = {};

$: if(selectedOptions.length > 0 && $subGroups) result = findResultInRules(selectedOptions, selectedOptions[0].identifyRules)


onMount(() => {
    selectedOptions = JSON.parse(localStorage.getItem('typo_selectedOptions'));
    detectionPath = JSON.parse(localStorage.getItem('typo_detectionPath'));
    selectedCharacteristics = JSON.parse(localStorage.getItem('typo_selectedCharacteristics'));
    // result = findResult(selectedOptions);
});

function findResultInRules(options, rules) {
    
    let res = rules.find(rule => {
        let isMatch = true;
        let rulePath = rule.value.path;
        for(let i = 0; i < rulePath.length; i++) {
            if(rulePath[i]._id != options[i+1]._id)
                isMatch = false;
        }
        return isMatch;
    });

    let resGroup = null;

    if(res != null) {
        resGroup = $subGroups.find(sG => sG._id === res.value.result._id)
    }
    return resGroup;
}

function findResult(options, src) {
    if(src) {
        let results = src.filter(t =>  {
                let isMatch = true;
                options.forEach(obj => {
                    if(obj.link) {
                        let linkedProp = t[obj.link];
                        if (linkedProp == null) {
                           
                            linkedProp = t[obj.link.slice(0,-1)]; //slice away last character because of database inconsistency between "identifySubGroups" (on indentifyPages) and "identifySubGroup" (on typefaces)
                        }
                        
                    if(linkedProp == null || linkedProp._id != obj._id)
                        isMatch = false;
                    }
                    else {
                        if(t.identifyGroup._id != obj._id)
                            isMatch = false;
                    }
                });
                return isMatch;
            });
        if(results.length > 0)
            return results[0];
    }
    return null;
}
</script>

<style>
header {
	background-color: var(--identify-nav);
}
main {
	background-color: var(--identify-main);
}
.resultBox > div{
    display: grid;
    padding: 11.5px 14px 13px 14px;
    grid-template-columns: 2fr 3fr;
}
.resultBox > .result:not(:nth-of-type(2)){
    height: 73px;
}
/* .resultBox > div.result:nth-of-type(2){
    margin-bottom: 20px;
} */
.icon.big img{
    max-height: 120%;
    margin-top: -3px;
}
/* .result{
    margin-bottom: 44px;
} */
.resultBox{
    margin-top: 56px;
}
</style>